import { FavouriteBtn } from "@entities/game";
import { MobileMenuButton } from "@features/mobile-menu-button";
import { Game } from "@lobby/core/entities/game";
import { SVGIcon } from "@shared/ui";
import { Link } from "@tanstack/react-router";
import { useState } from "react";

interface IGamePageControlsMobileProps {
  gameStringId: string;
}

export function GamePageControlsMobile({ gameStringId }: IGamePageControlsMobileProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const favouriteMutation = Game.useFavouriteSetter();
  const game = Game.getGameByStringId(gameStringId);

  const isFavourite = Boolean(game?.isFavourite);
  const gameId = Number(game?.id);

  return (
    <div
      className={`fixed top-[6.875rem] right-0 z-[100] bg-raisin-black/50 transition-transform ${isExpanded ? "translate-x-0" : "translate-x-1"}`}
    >
      <div className="flex items-center">
        <div
          className="-translate-x-1 fixed top-0 left-0 h-full w-8 flex-center border-dark-charcoal border-r-1 bg-raisin-black/50 backdrop-blur-sm"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <SVGIcon
            className={`size-4 text-accent ${isExpanded ? "rotate-0" : "rotate-180"}`}
            name="arrowRight"
          />
        </div>
        <div className="flex h-full items-center px-1.5 *:flex-center *:p-3">
          <div>
            <Link to="/casino" search>
              <SVGIcon className="size-5 text-accent" name="close" />
            </Link>
          </div>

          <div>
            <FavouriteBtn
              className="*:[&&]:size-5"
              isFavourite={isFavourite}
              onClick={() => favouriteMutation.mutate({ gameId, isFavourite: !isFavourite })}
            />
          </div>

          <div>
            <MobileMenuButton />
          </div>
        </div>
      </div>
    </div>
  );
}
