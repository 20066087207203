import { clsx } from "clsx";
import type { HTMLAttributes, PropsWithChildren } from "react";

export function FloatGroup({
  className,
  children,
  ...restProps
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  return (
    <div
      className={clsx(
        "fixed bottom-2.5 z-[999] flex mobile-only:w-full max-w-full flex-col items-end mobile-only:px-3 lg:right-10 lg:bottom-10",
        className,
      )}
      {...restProps}
    >
      {children}
    </div>
  );
}
