import { useJackpotsContext } from "@lobby/core/entities";
import { useAuth } from "@lobby/core/shared";
import { Skeleton } from "@shared/ui/skeleton";
import { clsx } from "clsx";
import { useEffect, useRef, useState } from "react";
import { JackpotsTicker } from "./jackpots-ticker";

export function JackpotsGuard() {
  const { isAuth } = useAuth();

  return isAuth && <Jackpots />;
}

const getTopOffset = (element: Element) => (element as HTMLElement).offsetTop;

function Jackpots() {
  const jackpotsRef = useRef<HTMLDivElement>(null);
  const [isWrapped, setIsWrapped] = useState(false);

  const { slots } = useJackpotsContext();

  const isSlotsExist = slots && Array.isArray(slots) && slots.length > 0;

  useEffect(() => {
    if (!jackpotsRef.current) return;

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const parent = entry.target as HTMLDivElement;
        const children = parent.children;

        if (children.length < 2) return;

        setIsWrapped(getTopOffset(children[1]) > getTopOffset(children[0]));
      }
    });

    observer.observe(jackpotsRef.current);

    return () => observer.disconnect();
  }, [isSlotsExist]);

  return isSlotsExist ? (
    <div ref={jackpotsRef} className={clsx("jackpots", isWrapped && "jackpots_wrapped")}>
      <div className="jackpots__tickers-group">
        <JackpotsTicker jackpotId={0} />
        <JackpotsTicker jackpotId={1} />
      </div>
      <div className="jackpots__tickers-group">
        <JackpotsTicker jackpotId={2} />
        <JackpotsTicker jackpotId={3} />
      </div>
    </div>
  ) : (
    <Skeleton className="h-[7.625rem] lg:h-16" />
  );
}
