import type { ApiClientResponse } from "@lobby/api-client";
import { FortuneWheel as FortuneWheelModel, Lobby } from "@lobby/core/entities";
import { APIError, emitter, useFortuneWheelCountdown } from "@lobby/core/shared";
import { useQueryClient } from "@tanstack/react-query";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import {
  WHEEL_RADIUS as R,
  WHEEL_DIAMETER,
  WHEEL_INITIAL_VELOCITY,
  buildSectorPath,
  calcAcceleration,
  calcCircumference,
  calcDistance,
  calcPointX,
  calcPointY,
  calcSectorAngle,
  calcTotalRotation,
  getSectorColors,
  getSectorFilling,
  getWinSectorIndex,
  WHEEL_INNER_RADIUS as r,
} from "../lib";
import type { TSector, TSectorParams, TWheelState, TWinSector } from "../lib";
import {
  BigArrow,
  InnerCircleDiamond,
  InnerCircleLamp,
  MiddleCircle,
  OuterCircle,
  OuterCircleDiamond,
  OuterCircleHandle,
  SpinButton,
  UpperArrow,
  UpperArrowBack,
  WheelSector,
} from "./components";

interface IFortuneWheelProps {
  className?: string;
  sectors: TSector[];
  onWinAnimationEnd?: (params: TSectorParams) => void;
}

export const FortuneWheel = memo(function FortuneWheel({
  className,
  sectors,
  onWinAnimationEnd,
}: IFortuneWheelProps) {
  const [wheelState, setWheelState] = useState<TWheelState>("idle");
  const [winnerSector, setWinnerSector] = useState<TWinSector>();
  const wheelRef = useRef<SVGGElement>(null);

  const queryClient = useQueryClient();
  const { mutate: spinWheel } = FortuneWheelModel.useWheelSpin();
  const { data: lobbyData } = Lobby.useLobby();

  const countdown = useFortuneWheelCountdown(lobbyData?.fortuneWheel?.nextSpinTime as number);

  const winSectorId = winnerSector?.sectorId;
  const winSectorIndex = useMemo(
    () => getWinSectorIndex(sectors, winSectorId),
    [sectors, winSectorId],
  );

  const sectorAngle = calcSectorAngle(sectors.length);
  const sectorPath = buildSectorPath(R, r, sectorAngle, 1);
  const sectorInnerShadowPath = buildSectorPath(R, r, sectorAngle - 1, 0.5);
  const sectorTextX = calcPointX(R, r - 60, -90 - 2);

  function invalidateWheelState() {
    queryClient.invalidateQueries({ queryKey: ["Lobby.getCurrent"] });
  }

  function handleSpinMutationSuccess({ result, error }: ApiClientResponse<"FortuneWheel.getWin">) {
    if (result) {
      setWheelState("spinning");
      setWinnerSector(result);
    }

    if (error) {
      setWheelState("ready");
      invalidateWheelState();

      const apiError = new APIError(error.message, {
        code: error.code,
      });

      emitter.emit("ERROR_MODAL_OPEN", apiError);
    }
  }

  function handleSpinClick() {
    setWheelState("fetching");
    spinWheel(undefined, { onSuccess: handleSpinMutationSuccess });
  }

  useEffect(() => {
    if (wheelState === "win" && winnerSector && onWinAnimationEnd) {
      setTimeout(
        () =>
          onWinAnimationEnd({
            id: winnerSector.sectorId,
            value: winnerSector.win,
            colors: getSectorColors(winSectorIndex),
          }),
        2000,
      );
    }
  }, [wheelState, winSectorIndex, winnerSector]);

  useEffect(() => {
    if (wheelState !== "spinning" || wheelRef.current === null) {
      return;
    }

    const wheel = wheelRef.current;
    const circumference = calcCircumference(R);
    const totalRotation = calcTotalRotation(sectors.length, winSectorIndex);
    const totalDistance = (totalRotation / 360) * circumference;
    const initialVelocity = WHEEL_INITIAL_VELOCITY;
    const acceleration = calcAcceleration(initialVelocity, totalDistance);

    let lastDistance = 0;
    const startTime = performance.now();

    function animate(time: DOMHighResTimeStamp) {
      const elapsed = time - startTime;

      const distance = calcDistance(initialVelocity, elapsed, acceleration);
      if (lastDistance > distance) {
        setWheelState("win");
        return;
      }
      lastDistance = distance;

      const angle = (distance / circumference) * 360;

      wheel.setAttribute("transform", `rotate(${angle} ${R} ${R})`);

      requestAnimationFrame(animate);
    }

    const requestId = requestAnimationFrame(animate);

    () => cancelAnimationFrame(requestId);
  }, [wheelState]);

  useEffect(() => {
    setWheelState(countdown > 0 ? "idle" : "ready");
  }, [countdown]);

  return (
    <div className={className}>
      <svg className="select-none" viewBox={`0 0 ${WHEEL_DIAMETER} ${WHEEL_DIAMETER}`}>
        <UpperArrowBack x={R} y={-10} />

        <OuterCircle />

        {Array.from({ length: 12 }).map((_, i) => (
          <use
            key={i}
            href="#outer-circle-diamond"
            x={R - 47 / 2}
            y={8}
            width="47"
            height="47"
            transform={`rotate(${i * (360 / 12)} ${R} ${R})`}
          />
        ))}

        <g transform={`rotate(${45 / 2} ${R} ${R})`}>
          {Array.from({ length: 24 }).map((_, i) => (
            <use
              key={i}
              href="#outer-circle-handle"
              x={R - 15 / 2}
              y={23}
              width="15"
              height="15"
              transform={`rotate(${i * (360 / 24)} ${R} ${R})`}
            />
          ))}
        </g>

        <circle cx="50%" cy="50%" r={r} fill="black" />

        {/* Sectors group */}
        <g ref={wheelRef}>
          {sectors.map(({ id }, i) => (
            <>
              <WheelSector
                key={i}
                path={sectorPath}
                fill={getSectorFilling(i)}
                transform={`rotate(${i * sectorAngle} ${R} ${R})`}
                isWinSector={id === winSectorId && wheelState === "win"}
              />
              <path
                key={`${i}-shadow`}
                fill="#000"
                fillOpacity="0.1"
                transform={`rotate(${i * sectorAngle} ${R} ${R})`}
                d={sectorInnerShadowPath}
              />
            </>
          ))}

          <path
            fill="url(#paint27_radial_572_1481)"
            d="M384.49 708.078c-178.775 0-323.716-144.941-323.716-323.715 0-178.775 144.941-323.716 323.716-323.716s323.716 144.941 323.716 323.716c0 178.774-144.941 323.715-323.716 323.715Z"
          />
          <path
            fill="url(#paint28_radial_572_1481)"
            d="M384.49 708.078c-178.775 0-323.716-144.941-323.716-323.715 0-178.775 144.941-323.716 323.716-323.716s323.716 144.941 323.716 323.716c0 178.774-144.941 323.715-323.716 323.715Z"
          />
          <g>
            {sectors.map(({ value }, i) => {
              value = value / 100;
              return (
                <g transform={`rotate(${i * sectorAngle} ${R} ${R})`} key={i}>
                  {value
                    .toString()
                    .split("")
                    .map((char, j) => (
                      <text
                        key={`${i}-${j}`}
                        x={sectorTextX}
                        y={calcPointY(R, r - 40 - j * 40, -100)}
                        fill="#fff"
                        className="font-extrabold text-4xl tracking-[1rem] [text-shadow:0_1px_3px_rgba(0,0,0,0.5)]"
                      >
                        {char}
                      </text>
                    ))}
                </g>
              );
            })}
          </g>
        </g>

        <g>
          <MiddleCircle x={R} y={R} />
          <g>
            {Array.from({ length: 12 }).map((_, i) => {
              const dy = 270;
              const transform = `rotate(${i * (360 / 12)} ${R} ${R})`;

              return (
                <>
                  <use
                    key={`${i}-diamond`}
                    href="#inner-circle-diamond"
                    x={R - 27 / 2}
                    y={dy - 27 / 2}
                    width="27"
                    height="27"
                    transform={transform}
                  />
                  <use
                    key={`${i}-lamp`}
                    href="#inner-circle-lamp"
                    x={R - 40 / 2}
                    y={dy - 40 / 2}
                    width="40"
                    height="40"
                    transform={transform}
                  />
                </>
              );
            })}
          </g>

          <SpinButton
            x={R}
            y={R}
            isReadyToSpin={wheelState === "ready"}
            onClick={handleSpinClick}
          />
        </g>

        <UpperArrow x={R} y={-13} />
        <BigArrow x={639} y={300} isTimerActive={wheelState === "idle"} />

        <defs>
          <OuterCircleDiamond />
          <OuterCircleHandle />
          <InnerCircleDiamond />
          <InnerCircleLamp />

          <linearGradient
            id="paint2_linear_572_1481"
            x1="671.003"
            x2="98.65"
            y1="6.326"
            y2="763.326"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".44" stopColor="#fff" stopOpacity="0" />
            <stop offset=".52" stopColor="#fff" />
            <stop offset=".8" stopColor="#fff" stopOpacity="0" />
          </linearGradient>
          <radialGradient
            id="paint1_radial_572_1481"
            cx="0"
            cy="0"
            r="1"
            gradientTransform="rotate(90 0 384.826) scale(380.5)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".87" stopOpacity=".4" />
            <stop offset=".91" stopColor="#141414" stopOpacity="0" />
            <stop offset=".95" stopOpacity="0" />
            <stop offset=".98" stopOpacity=".4" />
          </radialGradient>
          <radialGradient
            id="paint0_radial_572_1481"
            cx="0"
            cy="0"
            r="1"
            gradientTransform="rotate(90 0 384.826) scale(380.5)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".99" stopColor="#fff" stopOpacity="0" />
            <stop offset="1" stopColor="#fff" stopOpacity=".7" />
          </radialGradient>
          <radialGradient
            id="paint27_radial_572_1481"
            cx="0"
            cy="0"
            r="1"
            gradientTransform="rotate(90 .063 384.427) scale(323.716)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".4" stopOpacity=".5" />
            <stop offset=".43" stopOpacity=".05" />
            <stop offset=".49" stopOpacity="0" />
            <stop offset=".9" stopOpacity="0" />
            <stop offset=".99" stopOpacity=".26" />
            <stop offset="1" stopOpacity=".9" />
          </radialGradient>
          <radialGradient
            id="paint28_radial_572_1481"
            cx="0"
            cy="0"
            r="1"
            gradientTransform="rotate(90 .063 384.427) scale(323.716)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".54" stopColor="#fff" stopOpacity="0" />
            <stop offset=".83" stopColor="#fff" stopOpacity=".4" />
            <stop offset=".92" stopColor="#fff" stopOpacity=".1" />
            <stop offset=".99" stopColor="#fff" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
});
