import boxImg from "@app/assets/take-bonus-btn_box.avif";
import coinsImg from "@app/assets/take-bonus-btn_coins.avif";
import { emitter } from "@app/config";
import { Player } from "@lobby/core/entities";
import { usePlayerMoneyFormatter } from "@lobby/core/entities";
import { TextFitter } from "@lobby/core/shared";
import { clsx } from "clsx";
import type { HTMLAttributes } from "react";

export function TakeBonusButton(props: HTMLAttributes<HTMLDivElement>) {
  const { data } = Player.useBalances();
  const bonus = data?.bonus as {
    amount: number;
    type: string;
  };

  return bonus && <TakeBonusButtonView type={bonus.type} amount={bonus.amount} {...props} />;
}

interface ITakeBonusButtonProps {
  type: string;
  amount: number;
}

export function TakeBonusButtonView({
  className,
  type,
  amount = 0,
  ...restProps
}: ITakeBonusButtonProps & HTMLAttributes<HTMLDivElement>) {
  const activateBonusMutation = Player.useActivateBonus();
  const formatMoney = usePlayerMoneyFormatter();

  function handleClick() {
    emitter.emit("OPEN_CONGRATS_MODAL", {
      type: "bonus",
      winAmount: amount,
      onOk: () =>
        activateBonusMutation.mutate(undefined, {
          onSettled: () => emitter.emit("CLOSE_CONGRATS_MODAL"),
        }),
    });
  }

  return (
    <div
      onClick={handleClick}
      className={clsx(
        "group h-[8.375rem] min-w-80 max-w-full cursor-pointer rounded-10 p-[0.0625rem] [background-image:linear-gradient(90deg,#181410,#4F3F0C_41.5%,#FFEFAD_68.5%,#58460E)]",
        className,
      )}
      {...restProps}
    >
      <div className="relative h-full rounded-8 bg-gradient-to-r from-[#2D0B46] from-[9%] via-[#DBC19D] via-[55%] to-[#2D0B46] to-[87%] p-[0.0625rem]">
        <div
          style={{
            backgroundImage: `url(${coinsImg})`,
          }}
          className="absolute bottom-0 left-0 z-[1] h-10 w-full overflow-hidden rounded-inherit bg-contain"
        />
        <div className="relative h-full rounded-inherit bg-gradient-to-r from-[#5E00FF] from-[17%] to-[#2D0657] to-[56%] pt-6 pr-5 pb-9 pl-28 group-hover:from-[#8640FF] group-hover:to-[#380074]">
          <div className="font-bold uppercase leading-none tracking-tight [filter:drop-shadow(0_0.0625rem_0.125rem_#000)]">
            <div className="paint-stroke whitespace-nowrap text-[1.5625rem] [-webkit-text-stroke:0.125rem_#8E8E8E]">
              <TextFitter>{type}</TextFitter>
            </div>
            <div className="-mt-[0.1em] bg-golden-gradient bg-clip-text text-[2.5rem] text-transparent [-webkit-text-stroke:0.0625rem_#FFCA58]">
              <TextFitter>{formatMoney(amount)}</TextFitter>
            </div>
          </div>
          <div className="-top-[2.0625rem] -left-[4.375rem] group-hover:-translate-y-[0.3125rem] absolute z-[1] w-fit transition-transform duration-500">
            <img src={boxImg} alt="box" className="size-[11rem] object-contain" />
          </div>
        </div>
      </div>
    </div>
  );
}
