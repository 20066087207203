import { useJackpotsContext, usePlayerMoneyFormatter } from "@lobby/core/entities";
import { clsx } from "clsx";

interface IJackpotsTickerProps {
  className?: string;
  jackpotId: number;
}

export function JackpotsTicker({ className, jackpotId }: IJackpotsTickerProps) {
  const { slots } = useJackpotsContext();
  const formatMoney = usePlayerMoneyFormatter();

  if (!slots) return null;

  const type = slots[jackpotId].name;
  const value = slots[jackpotId].value;

  return (
    <div
      className={clsx(`jackpots__ticker jackpots-ticker jackpots-ticker_type_${type}`, className)}
      data-jp-ticker-id={jackpotId}
    >
      <div className="jackpots-ticker__inner">
        <div className="jackpots-ticker__text">
          <span className="text-[1.125em] lg:text-[1.625em]">
            {type} {formatMoney(value)}
          </span>
        </div>
      </div>
    </div>
  );
}
